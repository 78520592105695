* {
 box-sizing: border-box; 
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.menu-parent {  position: relative;}
.menu-parent:hover .menu-child {
  display: block;
}

.scroll {
  overflow: hidden;
}


/** Modified select Material UI */
.MuiInput-underline:before { border: none!important;}
.MuiInput-underline:after { border: none!important;}
.MuiInput-underline:focus { border: none;}
.MuiSelect-select.MuiSelect-select:focus { background-color: transparent; }
.MuiListItem-root.Mui-selected {font-size: 12px!important;}

.error {
  padding-right: 10px;
  align-self: flex-end;
  font-size: 10px;
}

#toast-container > div {
  box-shadow: 0px 0px 12px 5px rgba(0,0,0,.4) !important;
}

/** Tooltip */
.tooltip:hover .tooltiptext {
  visibility: visible;
}



.button {
  position: relative;
  padding: 16px 24px;
  border: none;
  background-color: #f5a717;
  color: #fff;
  font-size: 1.5rem;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.buttonEffect {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6);
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
  border-radius: 50%;
  animation: ripple 1s;
  opacity: 0;
}
@keyframes ripple {
  from {
     opacity: 1;
     transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(10);
  }
}